import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import Sidebar from "../sidebar/Sidebar";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DrawerPrecheckin from "../../components/DrawerPrecheckin";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useHotel } from "../../contexts/HotelProvider";
import { useUser } from "../../contexts/UserProvider";

export default function ContainerMain(props) {
  const drawerWidth = 240;
  const [open, setOpen] = React.useState(false);
  const [selectedHotel, setSelectedHotel] = React.useState();

  const { user, getUserData } = useUser();
  const { hotel, changeHotel } = useHotel();

  const navigate = useNavigate();
  
  const [openDrawerPrecheckin, setOpenDrawerPrecheckin] = useState(false);
  const [openDrawerPrecheckinGroup, setOpenDrawerPrecheckinGroup] =
    useState(false);

  useEffect(() => {
    console.log("useEffect container main by hotel")
    getUserData()
  }, [])

  useEffect(() => {
    console.log("useEffect container main by user")
    if(!selectedHotel && user && user.hotels) {
      console.log("user.hotels[0].hotel_uuid: ", user.hotels[0].hotel_uuid)
      handleChangeHotel(user.hotels[0].hotel_uuid)
    } else {
      console.log("selectedHotel: ", selectedHotel)
    }
  }, [user])

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const closeDrawerPrecheckin = (reload) => {
    setOpenDrawerPrecheckin(false);
    if (reload) {
      // TODO add o reload aqui da lista do precheckin, validar ainda se faz sentido chamar nesse ponto
    }
  };

  const closeDrawerPrecheckinGroup = (reload) => {
    setOpenDrawerPrecheckinGroup(false);
    if (reload) {
      // TODO add o reload aqui da lista do precheckin, validar ainda se faz sentido chamar nesse ponto
    }
  };

  const handleChangeHotel = async (hotel_uuid) => {
    await changeHotel(hotel_uuid);
    setSelectedHotel(hotel_uuid);
    //navigate("/dashboard");
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{ pr: "24px", alignItems: "center", justifyContent: "center" }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <div
            style={{
              width: "100%",
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/fives-white.png"}
              height={24}
              width={65}
              alt="Fives"
              style={{ marginRight: 5 }}
            />
            <span style={{ marginTop: 1022 }}>| Controller</span>

            {user && user.hotels &&
            <TextField
              select
              value={selectedHotel}
              onChange={(e) => handleChangeHotel(e.target.value)}
              size="small"
              sx={{ width: 300, marginLeft: 2, backgroundColor: "#fff", borderRadius: 1 }}
            >
              {user.hotels.map((option) => (
                <MenuItem key={option.hotel_uuid} value={option.hotel_uuid}>
                  {option.hotel_name}
                </MenuItem>
              ))}
            </TextField>
        }
          </div>
          <Box
            component="p"
            mr={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <Typography
              component="span"
              variant="subtitle2"
              color="inherit"
              sx={{ flexGrow: 1, fontSize: 16, fontWeight: "bold" }}
            >
              {user ? user.name : ""}
            </Typography>
            <Typography
              component="span"
              variant="caption"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.5,
                marginTop: -5,
              }}
            >
              {hotel ? hotel.name : "hotel"}
            </Typography>
          </Box>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={logout}
            size={"medium"}
            sx={{
              marginRight: "24px",
              borderRadius: 8,
            }}
          >
            <LogoutOutlined />
          </IconButton>
        </Toolbar>
      </AppBar>

      {openDrawerPrecheckin ? (
        <DrawerPrecheckin {...props.hotel} close={closeDrawerPrecheckin} />
      ) : null}

      {openDrawerPrecheckinGroup ? (
        <DrawerPrecheckin
          {...props.hotel}
          inGroup={true}
          close={closeDrawerPrecheckinGroup}
        />
      ) : null}

      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <Sidebar />
        </List>
      </Drawer>

      <Outlet />
    </Box>
  );
}
