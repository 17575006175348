import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { title } from "./Login.style";
import { HOTEL_UUID } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { useHotel } from "../contexts/HotelProvider";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import Loading from "../components/Loading";
import { useUser } from "../contexts/UserProvider";
import { TOKEN_KEY } from "../App";

// TODO
// Verificar no loading se o cliente esta em dia com os pagamentos
// Caso não, mostrar o banner de pagamento atrasado, caso passe de 30 dias
// deve bloquear o acesso

const theme = createTheme();

export default function SplashScreen() {
  let navigate = useNavigate();
  
  const { getHotelData } = useHotel();
  const { getUserData } = useUser();

  useEffect(() => {
    if (!localStorage.getItem(TOKEN_KEY)) {
      navigate("/login", { replace: true });
    } else {
      loadUserData();
    }
  }, [])


  const loadUserData = () => {
    getUserData()
      .then(async (response) => {
        console.log('response: ', response)
        if(!response) {
          return
        }
        
        const userData = response;
        
        let hotel_uuid = localStorage.getItem(HOTEL_UUID) || userData.hotel_uuid;
        if (userData.hotels.length > 0) {
          hotel_uuid = userData.hotels[0].hotel_uuid
        }

        localStorage.setItem(HOTEL_UUID, hotel_uuid);

        await getHotelData(hotel_uuid)
        
        setTimeout(() => {
          navigate("/dashboard", { replace: true });          
        }, 1000);
      })
      .catch(() => {
        toast.error("Ocorreu um problema, reface o login!");
        localStorage.clear();
        navigate("/login");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img
            src={process.env.PUBLIC_URL + "/fives.png"}
            height={56}
            width={149}
            alt="Fives"            
          />

          <span style={title}>Preparando o ambiente, aguarde ...</span>
          <Loading />
        </Box>
      </Grid>
    </ThemeProvider>
  );
}
