import "./index.css";
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import MyRoutes from "./router/routers";
import Poppins from "./fonts/Poppins/Poppins-Regular.ttf";
import axios from "axios";
import { NotificationProvider } from "./contexts/NotificationProvider";
import { HotelProvider } from "./contexts/HotelProvider";
import { UserProvider } from "./contexts/UserProvider";

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#4177F6",
    },
    secondary: {
      main: "#ECF1FE",
    },
  },
  typography: {
    fontFamily: ["Poppins"].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Poppins'), local('Poppins-Regular'), url(${Poppins}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

export const TOKEN_KEY = "fivesapp-token";

axios.defaults.baseURL = "https://api.fivesapp.com.br"
//axios.defaults.baseURL = "http://localhost:5000"

axios.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem(TOKEN_KEY)) {
      config.headers.Authorization = localStorage.getItem(TOKEN_KEY);
    }
    config.headers["api-key"] =
      "BPjzdOtLUBCm7OyFmtEoLNAp5-txD9RCCJt5iyqOHAMM8Ya9t0BKhSt5agkMq3az508fCnTaetQ3K_Rl7IHNc7U";
    return config;
  },
  (error) => Promise.reject(error)
);

export default function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <HotelProvider>
        <UserProvider>
          <NotificationProvider>
            <MyRoutes />
          </NotificationProvider>
        </UserProvider>
      </HotelProvider>
      <ToastContainer position="top-right" autoClose={5000} />
    </ThemeProvider>
  );
}
