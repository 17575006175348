import React, { useState, useEffect } from "react";
import { getCountActivesGuestsHotel } from "./../../api/guestController";
import { getGoogleRatings, getTripAdvisorRatings } from "./../../api/rateController";
import { getCountActivesTicketsHotel } from "./../../api/ticketController";
import DashboardScreen from "./Dashboard.screen";
import { useHotel } from "../../contexts/HotelProvider";

export default function DashboardContainer() {
  const [countActiveGuests, setCountActiveGuests] = useState(0);
  const [countRatings, setCountRatings] = useState(0);
  const [googleRating, setGoogleRating] = useState(0);
  const [tripAdvisorRating, setTripAdvisorRating] = useState(0);
  const [countOpenNotifications, setCountOpenNotifications] = useState(0);
  const [loading, setLoading] = useState(true);
  const { hotel } = useHotel();

  async function notifica() {
    if (Notification.permission !== "granted") {
      await Notification.requestPermission();
    }
  }

  useEffect(() => {
    setLoading(true)
    notifica();

    if(hotel) {
      setCountRatings(hotel.numRatings);      
    }

    const fetchData = async () => {
      try {
        if(!hotel) {
          return
        }
        const dataGuest = await getCountActivesGuestsHotel(hotel.id)
        setCountActiveGuests(dataGuest ? dataGuest.count : 0);

        const dataTicket = await getCountActivesTicketsHotel(hotel.id)
        setCountOpenNotifications(dataTicket ? dataTicket.count : 0);

        const dataGoogle = await getGoogleRatings(hotel.id)
        setGoogleRating(dataGoogle ? dataGoogle.rating : 0);

        const dataTripAdvisor = await getTripAdvisorRatings(hotel.id)
        setTripAdvisorRating(dataTripAdvisor ? dataTripAdvisor.rating : 0);
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [hotel]);

  return (
    <DashboardScreen
      hotel={hotel}
      countActiveGuests={countActiveGuests}
      countRatings={countRatings}
      countOpenNotifications={countOpenNotifications}
      loading={loading}
      googleRating={googleRating}
      tripAdvisorRating={tripAdvisorRating}
    />
  );
}
